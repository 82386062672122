import env from "./env";
import { HttpClient } from "./httpClient";
import { IProfile, DProfile } from '../Models/IProfile.model';
import { jwtDecode } from "jwt-decode";

export interface IUserProfilesService {
  create: (profile: IProfile) => Promise<IProfile>;
  //create: (username: string, password: string) => Promise<IProfile>;
  login: (username: string, password: string) => Promise<IProfile>;
  logout: () => void;
  isUserLogin: () => boolean;
  getUserName: () => string | null;
  getAccessToken: () => string | null;
}

class UserProfilesService extends HttpClient implements IUserProfilesService {
  /* constructor() {
    super();
  }
 */
  public async create(profile: IProfile): Promise<IProfile> {
    try {
      const url = `${env.APP_API_HOST}/auth/register`;
      const response = await this.post(url, {
        muId: profile.muId,
        email: profile.email,
        username: profile.username,
        password: profile.muId,
        fname: profile.fname,
        lname: profile.lname,
        year: profile.year,
        role: profile.role
      });
      if (response.status === undefined) {  //server failure
        throw new Error("ไม่สำเร็จ กรุณารอสักครู่ และลองใหม่อีกครั้งภายหลัง");
      }

      if (!response.data) {
        throw new Error("ไม่สำเร็จ ลองใหม่อีกครั้งภายหลัง");
      }

      const data: any = response.data;
      if (data.status === "error") {
        throw new Error("ไม่สำเร็จ กรุณาลองใหม่อีกครั้ง");
      }
      //access_token
      const decoded: any = jwtDecode(data.access_token);
      var newplayer = DProfile();
      newplayer.email = decoded.email;
      newplayer.id = decoded.sub;
      newplayer.role = decoded.role;

      return newplayer;
    } catch (e) {
      throw e;
    }
  }

  //--------------------------------------------------//
  //--------------------------------------------------//
  public async login(username: string, password: string): Promise<IProfile> {
    try {
      const url = `${env.APP_API_HOST}/auth/login`;
      const response = await this.post(url, {
        username: username,
        password: password,
      });
      if (response.status === undefined) {  //server failure
        throw new Error("ไม่สำเร็จ กรุณารอสักครู่ และลองใหม่อีกครั้งภายหลัง");
      }

      if (!response.data) {
        throw new Error("บัญชีหรือรหัสผ่านไม่ถูกต้อง กรุณาตรวจสอบอีกครั้ง");
      }

      const data: any = response.data;
      if (data.status === "error") {
        throw new Error("ไม่สำเร็จ กรุณาลองใหม่อีกครั้ง");
      }

      const decoded: any = jwtDecode(data.access_token);
      localStorage.setItem("accessToken", data.access_token);
      localStorage.setItem("userId", decoded.sub);
      localStorage.setItem("email", decoded.email);
      localStorage.setItem("role", decoded.role);
      localStorage.setItem("start_date", decoded.iat);
      localStorage.setItem("expire_date", decoded.exp);
      return data;
    } catch (e) {
      throw e;
    }
  }

  public logout() {
    console.log("logout");
    localStorage.setItem("accessToken", "");
    localStorage.setItem("userId", "");
    localStorage.setItem("email", "");
    localStorage.setItem("role", "");
    localStorage.setItem("start_date", "");
    localStorage.setItem("expire_date", "");
    localStorage.clear();
  }

  //--------------------------------------------------//
  //--------------------------------------------------//
  public isUserLogin() {
    if (localStorage.accessToken !== undefined && localStorage.accessToken !== "") {
      if (Date.now() < Number(localStorage.expire_date) * 1000) {
        return true;
      }
    }
    this.logout();
    return false;
  }

  public getUserName(): string | null {
    return this.isUserLogin() ? localStorage.username : null;
  }

  public getAccessToken(): string | null {
    return localStorage.accessToken;
  }

  //--------------------------------------------------//
  //--------------------------------------------------//
  public async getProfile(id: string | null) {
    try {
      if (id === null) throw new Error('ไม่พบข้อมูล');
      const url = `${env.APP_API_HOST}/users/${id}/profile`;
      const response = await this.get(url);

      if (response.status === undefined) {  //server failure
        throw new Error("ไม่สำเร็จ กรุณารอสักครู่ และลองใหม่อีกครั้งภายหลัง");
      }

      if (!response.data) {
        throw new Error("บัญชีหรือรหัสผ่านไม่ถูกต้อง กรุณาตรวจสอบอีกครั้ง");
      }
      return response.data as IProfile;
    } catch (e) {
      throw e;
    }
  }
  public async updateProfile(id: string, data: Partial<IProfile>) {
    try {
      const url = `${env.APP_API_HOST}/auth/${id}/profile`;

      const response = await this.post(url, data);
      if (response.status === undefined) {  //server failure
        throw new Error("ไม่สำเร็จ กรุณารอสักครู่ และลองใหม่อีกครั้งภายหลัง");
      }
      if (response.status >= 400) {
        //403, 404
        throw new Error("ไม่สำเร็จ กรุณาลองใหม่อีกครั้ง");
      }
      return response.data as IProfile;
    } catch (e) {
      throw e;
    }
  }
  //--------------------------------------------------//
  //--------------------------------------------------//
  public async forgotPassword(email: string): Promise<string> {
    const url = `${env.APP_API_HOST}/auth/forgot-password/${email}`;
    const response = await this.get(url);

    if (!response.status) {
      throw new Error("ไม่สำเร็จ กรุณารอสักครู่ และลองใหม่อีกครั้งภายหลัง");
    }
    if (response.status >= 400) {
      //403, 404
      throw new Error("ไม่สำเร็จ กรุณาลองใหม่อีกครั้ง");
    }
    return "สำเร็จ กรุณาตรวจสอบอีเมล";
  }

  public async updatePassword(email: string, password: string): Promise<string> {
    try {
      const url = `${env.APP_API_HOST}/auth/reset-password`;
      const response = await this.post(url, {
        email: email,
        password: password,
      });

      if (!response.data) {   //server failure
        throw new Error("ไม่สำเร็จ กรุณารอสักครู่ และลองใหม่อีกครั้งภายหลัง");
      }
      const data = response.data as any;
      if (data.status >= 400) {
        //403, 404
        throw new Error("ไม่สำเร็จ กรุณาลองใหม่อีกครั้ง");
      }
      return "สำเร็จ";
    } catch (e) {
      throw e;
    }
  }
}

export default new UserProfilesService();
